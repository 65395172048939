




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref, reactive, computed, toRefs } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { from as ixfrom } from 'ix/asynciterable';
import { useUserGetters, useDbGetters, useRealmAppState } from '@/store';
import Loading from '@/components/Loading.vue';
import Profile from '@/components/Profile.vue';
import { headerValue } from '@/constants/directory';
import { DailyAction } from '@/constants/daily-action';
import VideoAsk from '@/components/VideoAsk.vue';
import { AdkState } from './MobileEmployer/types/program';
import { AdkName } from './MobileEmployer/types/adk';
import { Question, VideoaskEvent } from './Mobile/types/videoask';

/**
 * The adk link item names.
 */
type AdkLinkItem = 'proposal' | 'promote' | 'produce' | 'pilot' | 'process' | 'package';

/**
 * The dialog states for all link items + additional things.
 */
type DialogStateKey =
  | AdkLinkItem
  | 'program'
  | 'directory'
  | 'objective'
  | 'settings'
  | 'cowork'
  | 'calendar'
  | 'workshops'
  | 'timesheetMaster'
  | 'timesheet';
type DialogState = {
  [item in DialogStateKey]: boolean;
};

/**
 * Link items in the adk object.
 */
type AdkLinkItemState = {
  [item in AdkLinkItem]?: string;
};

/**
 * Final interface of the adk object.
 *
 * Contains additional data such as settings.
 *
 * Modify this interface when adding new properties.
 */
interface IAdkInfo extends AdkLinkItemState {
  calendarPreference?: string;
  acknowledge?: boolean;
  objectiveKeyResults?: Array<string>;
  clockAdjustedHours?: number;
  // rating feedback form
  ratingInternshipOverall?: number;
  ratingEmployerWeeklyMeeting?: number;
  ratingEmployerDirection?: number;
  ratingEmployerWorkingRelationship?: number;
  ratingCoworkingMood?: number;
  ratingProjectProgress?: number;
  ratingAttendingSocials?: number;
  ratingVax?: boolean;
  ratingInPersonSocials?: number;
  ratingWorkshop?: boolean;
  ratingWorkshopHelpfulness?: number;
  ratingNewFriends?: number;
  ratingWeeklyMeetingChange?: string;
  ratingCoworkingChange?: string;
  ratingAcknowledgeSignoff?: boolean;
  ratingAcknowledgeMinHours?: boolean;
  ratingAcknowledgeDeliverablesCompletion?: boolean;
  ratingNewDeadlineDate?: string;
  ratingOneImprovement?: string;
  sevenPointInternshipCompletion?: boolean;
  finalPresentationConfirmation?: boolean;
  finalPresentationAbsence?: string;
  exitInterviewComplete?: boolean;
  exitInterviewIncomplete?: boolean;
}

/**
 * The adk object as stored in the db in `studentDoc.adks`.
 */
interface IAdk extends IAdkInfo {
  name: 'mobile';
}

/**
 * Final `studentDoc` interface with `programDoc` added.
 */
interface IDoc {
  _id: ObjectId;
  program_id: ObjectId;
  offerDetails: any;
  program: {
    programName: string;
    organizers: Array<ObjectId>;
    adks: AdkState;
  };
  adk?: IAdk;
}

interface ITimesheetDoc {
  _id: ObjectId;
  contact: {
    name: string;
    email: string;
    status: string;
    answers: [];
  };
  form: {
    questions: [];
  };
}

export default defineComponent({
  name: 'Mobile',

  components: {
    Loading,
    Profile,
    VideoAsk
  },

  setup(props, ctx) {
    // getting the db
    const { app } = useRealmAppState(['app']);
    const db = app.value.currentUser?.mongoClient('mongodb-atlas').db('Primary');
    const { collection } = useDbGetters(['collection']);
    const { getUser, getObjectId } = useUserGetters(['getUser', 'getObjectId']);
    const myDocs = ref<Array<IDoc>>([]);
    const user = ref<
      | {
          _id: ObjectId;
          firstName: string;
          lastName: string;
          email: string;
          profile: {
            medium: string;
          };
        }
      | undefined
    >();
    const adkLocalState = ref<IAdkInfo>({});
    const _selectedDoc = ref<IDoc | undefined>();
    const announcement5 = ref(false);
    const timeSheetDoc = ref<VideoaskEvent | undefined>();
    /**
     * Computed, current `studentDoc`. The setter updates `adkLocalState` to match the specific doc.
     */
    const selectedDoc = computed({
      get: () => _selectedDoc.value,
      set: newVal => {
        _selectedDoc.value = newVal;
        adkLocalState.value = { ...newVal?.adk };
      }
    });

    // for the objective results
    // const keyResultsValues = ref([]);

    const dialogState = reactive<DialogState>({
      calendar: false,
      cowork: false,
      proposal: false,
      promote: false,
      produce: false,
      pilot: false,
      process: false,
      package: false,
      program: false,
      directory: false,
      objective: false,
      settings: false,
      timesheet: false,
      timesheetMaster: false,
      workshops: false
    });

    // for the v-data table
    const users = ref<any[]>([]);
    // for students
    const studentData = ref<any[]>([]);
    // for organizers
    const organizerData = ref<any[]>([]);

    // for the program Doc Id
    // const programId = ref<ObjectId>();

    const loadData = async () => {
      const studentDocs = await collection.value!('Student').find(
        {
          participant_id: getObjectId.value,
          // https://docs.mongodb.com/manual/reference/operator/query/elemMatch/
          // Must have "offerStatus" true to view
          adks: {
            $elemMatch: {
              name: 'offer',
              offerStatus: true
            }
          }
        },
        {
          projection: {
            _id: 1,
            participant_id: 1,
            program_id: 1,
            offerDetails: 1,
            adks: {
              $elemMatch: {
                name: 'mobile'
              }
            }
          }
        }
      );

      user.value = await collection.value!('User').findOne(
        {
          _id: getObjectId.value
        },
        {
          projection: {
            _id: 1,
            firstName: 1,
            lastName: 1,
            email: 1,
            phoneNumber: 1,
            profile: {
              medium: 1
            }
          }
        }
      );

      const programDocs = await collection.value!('Program').find(
        {
          _id: { $in: studentDocs.map(student => student.program_id) }
        },
        {
          projection: {
            programName: 1,
            organizers: 1,
            adks: 1
          }
        }
      );

      myDocs.value = studentDocs.map((student): IDoc => {
        const program = programDocs.find(program => student.program_id.equals(program._id));
        return {
          _id: student._id,
          program_id: student.program_id,
          offerDetails: student.offerDetails,
          program: {
            programName: program.programName,
            organizers: program.organizers,
            adks: {
              mobile: program.adks.find((adk: { name: AdkName }) => adk.name === 'mobile'),
              directory: program.adks.find((adk: { name: AdkName }) => adk.name === 'directory'),
              objective: program.adks.find((adk: { name: AdkName }) => adk.name === 'objective')
            }
          },
          adk: student.adks?.[0]
        };
      });

      [selectedDoc.value] = myDocs.value;

      if (!selectedDoc.value) ctx.root.$router.push({ name: 'landing' });

      // Setup event listeners for VideoAsk
      // TODO: extract this to a separate composable hook
      const timesheetChangeStream = collection.value!('Timesheet').watch({
        filter: {
          'contact.variables.participant_id': { $eq: user.value?._id.toHexString() },
          'contact.variables.program_id': { $eq: selectedDoc.value?.program_id.toHexString() }
        }
      });
      // TODO: give this a proper type
      ixfrom<any>(timesheetChangeStream).forEach(change => {
        const questions = change?.fullDocument?.form?.questions ?? [];
        console.log('timesheetChangeStream triggered');
        // eslint-disable-next-line no-use-before-define
        setLastTimeSheetAction(questions);
      });

      const timeSheetDocs = await collection.value!('Timesheet').find(
        {
          'contact.variables.participant_id': { $eq: user.value?._id.toHexString() },
          'contact.variables.program_id': { $eq: selectedDoc.value?.program_id.toHexString() }
        },
        {
          sort: {
            'contact.created_at': -1 // TODO: add index for this
          },
          limit: 1
        }
      );
      const [lastTimeSheet] = timeSheetDocs;
      timeSheetDoc.value = lastTimeSheet;
      // eslint-disable-next-line no-use-before-define
      setLastTimeSheetAction(timeSheetDoc?.value?.form?.questions ?? []);
      announcement5.value = selectedDoc?.value?.adk?.exitInterviewComplete !== 'Yes';
    };

    const announcement4 = ref(true);

    function getAdkValue(key: keyof IAdkInfo) {
      return selectedDoc.value?.adk?.[key];
    }

    const discordLinkEmployer = computed(() => {
      return selectedDoc.value?.program.adks.mobile?.discordEmployerChannel;
    });

    const keyResultsValues = computed(() => {
      return selectedDoc.value?.program.adks.objective?.keyResults;
    });

    // for the employer objective
    const primaryObjectiveValue = computed(() => {
      return selectedDoc.value?.program.adks.objective?.primaryObjective;
    });

    // Final Presentation Date
    const finalPresentationDate = computed(() => {
      return selectedDoc.value?.program.adks.mobile?.finalPresentation;
    });

    /**
     * Get value from the adk object stored in the db.
     */
    /**
     * Reset local state for a link item to db value or empty when dialog closes.
     */
    function closeLinkItemDialog(key: AdkLinkItem) {
      dialogState[key] = false;
      adkLocalState.value[key] = getAdkValue(key) ?? '';
    }
    /**
     * Save value under the adk object to the db
     */
    async function saveAdkValue(keys: (keyof IAdkInfo)[]) {
      if (!selectedDoc.value?.adk) {
        const adkObj: IAdk = {
          name: 'mobile'
        };

        keys.forEach(key => {
          adkObj[key] = adkLocalState.value[key];
        });

        try {
          await collection.value!('Student').updateOne(
            {
              _id: selectedDoc.value?._id
            },
            {
              $push: {
                adks: adkObj
              }
            }
          );
          selectedDoc.value!.adk = adkObj;
        } catch (e) {
          console.log('Error while pushing new adk to studentDoc');
          throw e;
        }
      } else {
        const setObj = {};

        keys.forEach(key => {
          setObj[`adks.$.${key}`] = adkLocalState.value[key];
        });

        try {
          await collection.value!('Student').updateOne(
            {
              _id: selectedDoc.value?._id,
              adks: { $elemMatch: { name: 'mobile' } }
            },
            {
              $set: setObj
            }
          );
          keys.forEach(key => {
            selectedDoc.value.adk[key] = setObj[`adks.$.${key}`];
          });
        } catch (e) {
          console.log('Error while settings ADK values');
          throw e;
        }
      }
      keys.forEach(key => {
        dialogState[key] = false;
      });
    }

    const directoryLoaded = ref(false);

    async function loadDirectory() {
      const students = await db!.collection('Student').find(
        {
          program_id: selectedDoc.value?.program_id,
          offerDetails: { $exists: true }
        },
        {
          projection: {
            _id: 1,
            program_id: 1,
            participant_id: 1,
            offerDetails: 1,
            applicationStatus: 1
          }
        }
      );

      const organizers = await db!.collection('EmployerPortfolio').find(
        {
          _id: {
            $in: selectedDoc.value?.program.organizers
          }
        },
        {
          projection: {
            _id: 1,
            employerName: 1,
            jobTitle: 1
          }
        }
      );

      const studentIds: Array<ObjectId> = students.map(
        (student: { participant_id: ObjectId }) => student.participant_id
      );
      const organizerIds: Array<ObjectId> = organizers.map(
        (organizer: { _id: ObjectId }) => organizer._id
      );
      const userIds = studentIds.concat(organizerIds);
      const usersData = await db!.collection('User').find(
        {
          _id: {
            $in: userIds
          }
        },
        {
          projection: {
            _id: 1,
            firstName: 1,
            lastName: 1,
            phoneNumber: 1,
            email: 1
          }
        }
      );
      const studentsData = students.map((student: { participant_id: ObjectId }) => {
        return {
          user: usersData.find((user: { _id: ObjectId }) =>
            user._id.equals(student.participant_id)
          ),
          ...student
        };
      });
      const organizersData = organizers.map((organizer: { _id: ObjectId }) => {
        return {
          user: usersData.find((user: { _id: ObjectId }) => user._id.equals(organizer._id)),
          directory: selectedDoc.value?.program.adks.directory?.directoryPreferences.find(
            preference => preference.userId.equals(organizer._id)
          ),
          ...organizer
        };
      });
      // setting the studnet Doc value to student data
      studentData.value = studentsData;
      // setting the organizers Doc value to student data
      organizerData.value = organizersData;
      users.value = organizersData.concat(studentsData as any[]);

      directoryLoaded.value = true;
    }

    enum DirectoryView {
      all,
      employers,
      interns
    }

    const state = reactive({
      directoryView: DirectoryView.all,
      selectedDirectoryPreferences: undefined,
      openDialog: false,
      showInstructions: true,
      lastTimeSheetAction: ''
    });

    const setLastTimeSheetAction = (questions: Question[]) => {
      const clockin = questions.find(
        question =>
          question?.metadata?.text?.toLowerCase() === 'clock-in' &&
          question?.transcode_status === 'completed'
      );
      const clockout = questions.find(
        question =>
          question?.metadata?.text?.toLowerCase() === 'clock-out' &&
          question?.transcode_status === 'completed'
      );

      if (!!clockin && !clockout) {
        state.lastTimeSheetAction = DailyAction.clockin;
      } else if (!!clockout && !clockin) {
        state.lastTimeSheetAction = DailyAction.clockout;
      } else {
        state.lastTimeSheetAction = DailyAction.clockout;
      }

      console.log('setLastTimeSheetAction', state.lastTimeSheetAction);
    };

    const organizers = computed(() => {
      return organizerData.value?.map(organizer => {
        // ooverride email and phone with hidden value unless directory preferences are set otherwise
        const email =
          organizer.directory?.hideEmail === false ? organizer.user.email : '**********';
        const phoneNumber =
          organizer.directory?.hidePhone === false ? organizer.user.phoneNumber : '**********';
        return {
          ...organizer.user,
          email,
          phoneNumber,
          name: `${organizer.user.firstName} ${organizer.user.lastName}`,
          role: organizer.jobTitle,
          directory: organizer.directory
        };
      });
    });

    const students = computed(() => {
      return studentData.value
        ?.filter(student => student.applicationStatus === 'accepted')
        ?.map(student => {
          // comment this line if you want students phone number to show up
          const phoneNumber = '**********';
          return {
            ...student.user,
            // comment this line if you want students phone number to show up
            phoneNumber,
            name: `${student.user.firstName} ${student.user.lastName}`,
            role: student?.offerDetails?.position
          };
        });
    });

    const filterDatavalues = computed(() => {
      switch (state.directoryView) {
        case DirectoryView.all:
          return [...organizers.value, ...students.value];
        case DirectoryView.employers:
          return organizers.value;
        case DirectoryView.interns:
          return students.value;
        default:
          throw new Error(`Invalid directoryView value: ${state.directoryView}`);
      }
    });

    // calendar
    const calendarValue = ref(false);
    // for redirecting
    const redirectPage = () => {
      // _blank specifies the link to open in a new tab
      if (String(adkLocalState.value.calendarPreference) === 'false') {
        // eslint-disable-next-line no-restricted-globals
        window.open('https://calendar.google.com/');
        // window.open('https://calendar.google.com/', '_blank');
      } else {
        window.open('https://outlook.live.com/calendar');
        // window.open('https://outlook.live.com/calendar', '_blank');
      }
    };

    // for objective
    const testBox1 = ref([]);

    const lengthCheckbox = computed(() => {
      return testBox1.value.length;
    });

    const keyResultLength = computed(() => {
      return keyResultsValues.value?.length;
    });

    const acknowledge = computed(() => {
      return ref(getAdkValue('acknowledge'));
    });

    const diableAcknowledgebutton = () => {
      dialogState.objective = false;
      adkLocalState.value.acknowledge = true;
      adkLocalState.value.objectiveKeyResults = keyResultsValues.value;
      saveAdkValue(['acknowledge', 'objectiveKeyResults']);
    };
    // end of objective

    const clockedHoursProgress = computed(() => {
      const clockAdjustedHours = getAdkValue('clockAdjustedHours') as number;

      const { minHours } = selectedDoc?.value?.offerDetails ?? 1;
      const { maxHours } = selectedDoc?.value?.offerDetails ?? 1;

      if (Number.isNaN(minHours) || Number.isNaN(clockAdjustedHours)) {
        return 0;
      }

      // UNCOMMENT IF CALCULATION DIVIDED BY MIN HOURS TOO
      // if (clockAdjustedHours <= minHours) {
      //   return (clockAdjustedHours / minHours) * 100;
      // }

      return (clockAdjustedHours / maxHours) * 100;
    });

    const maximumClockedHours = computed(() => {
      const clockAdjustedHours = getAdkValue('clockAdjustedHours') as number;
      const { minHours } = selectedDoc?.value?.offerDetails ?? 1;
      const { maxHours } = selectedDoc?.value?.offerDetails ?? 1;
      // UNCOMMENT IF CALCULATATION DIVIDED BY MIN HOURS TOO
      // if (clockAdjustedHours <= minHours) {
      //   return minHours;
      // }
      return maxHours;
    });

    // for the rating values
    const announcement2 = ref(true);

    const savingRatings = async () => {
      try {
        await saveAdkValue([
          'ratingInternshipOverall',
          'ratingEmployerWeeklyMeeting',
          'ratingEmployerDirection',
          'ratingEmployerWorkingRelationship',
          'ratingCoworkingMood',
          'ratingProjectProgress',
          'ratingAttendingSocials',
          'ratingVax',
          'ratingInPersonSocials',
          'ratingWorkshop',
          'ratingWorkshopHelpfulness',
          'ratingNewFriends',
          'ratingWeeklyMeetingChange',
          'ratingCoworkingChange',
          'ratingAcknowledgeSignoff',
          'ratingAcknowledgeMinHours',
          'ratingAcknowledgeDeliverablesCompletion',
          'ratingNewDeadlineDate',
          'ratingOneImprovement'
        ]);
        console.log('Save questions succesfully');
        announcement2.value = false;
      } catch (error) {
        console.log('Failed to save questions.');
        console.log(error);
      }
    };
    // for the 7-Point Internship Completion
    const announcement3 = ref(true);
    const savingInternshipCompletion = async () => {
      try {
        await saveAdkValue(['sevenPointInternshipCompletion']);
        console.log('Save questions succesfully');
        announcement3.value = false;
      } catch (error) {
        console.log('Failed to save questions.');
        console.log(error);
      }
    };
    // for the Final Presentation Confirmation

    const savingFinalPresentation = async () => {
      try {
        await saveAdkValue(['finalPresentationConfirmation', 'finalPresentationAbsence']);
        console.log('Save questions succesfully');
        announcement4.value = false;
      } catch (error) {
        console.log('Failed to save questions.');
        console.log(error);
      }
    };

    // for Exit Interview
    // const announcement5 = ref(true);
    const savingExitInterview = async () => {
      try {
        const response = await saveAdkValue(['exitInterviewComplete', 'exitInterviewIncomplete']);
        console.log('Save questions succesfully');
        announcement5.value = false;
      } catch (error) {
        console.log('Failed to save questions.');
        console.log(error);
      }
    };

    // Interactions
    const menuNewDeadlineDate = ref(false);
    return {
      loadData,
      dialogState,
      user,
      myDocs,
      selectedDoc,
      adkLocalState,
      getAdkValue,
      saveAdkValue,
      closeLinkItemDialog,
      header: headerValue,
      items: filterDatavalues,
      directoryLoaded,
      loadDirectory,
      DirectoryView,
      ...toRefs(state),
      calendarValue,
      redirectPage,
      DailyAction,
      discordLinkEmployer,
      // returns for for objective
      testBox1,
      keyResultsValues,
      keyResultLength,
      lengthCheckbox,
      acknowledge,
      primaryObjectiveValue,
      finalPresentationDate,
      diableAcknowledgebutton,
      timeSheetDoc,
      clockedHoursProgress,
      maximumClockedHours,
      savingRatings,
      savingInternshipCompletion,
      savingFinalPresentation,
      savingExitInterview,
      menuNewDeadlineDate,
      newFriends: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30'
      ],

      // end of objective
      tutorialDialogProposal: false,
      tutorialDialogPromote: false,
      tutorialDialogProduce: false,
      tutorialDialogPilot: false,
      tutorialDialogProcess: false,
      tutorialDialogPackage: false,
      announcement1: true,
      takeover: true,
      announcement2,
      announcement3,
      announcement4,
      announcement5,
      hoursPriorLaunch: false,
      libraryUpcoming: [0],
      libraryCurrent: [0],
      libraryTooltip: true
    };
  }
});
